import { Constants } from './../constants';
import {_throw} from 'rxjs/observable/throw';
import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError, retry} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {throwError} from 'rxjs';
import {isNullOrUndefined} from 'util';

export interface NewImageInterface {
  id: number;
  path: string;
}

export interface NewInterface {
  id: number;
  title: string;
  content: string;
  createdAt: Date;
  updatedAt: Date;
  approved: number;
  images: NewImageInterface[];
  news_targets: NewTargetInterface[];
}

export interface NewTargetInterface {
  id: number;
  new_id: number;
  school_id: number;
  course_id: number;
  level_id: number;
  role_id: number;
  createdAt: string;
  updatedAt: string;
}

export interface CreateNewInterface {
  title: string;
  content: string;
  files?: FileList;
  rolesId?: number[];
  schoolsId?: number[];
  levelsId: number[];
  coursesId: number[];
  images: string;
}

export interface EditNewInterface {
  approved: number;
  title?: string;
  content?: string;
  images?: any;
  files?: FileList;
  id?: number;
}

export interface HttpResponseManyNews {
  ok: boolean;
  data?: {news: NewInterface[]};
}

export interface HttpResponseOneNew {
  ok: boolean;
  data?: {news: NewInterface};
}


@Injectable()
export class NewsService {

  NewsData = '../../assets/data/getNews.json';
  createNewsData = '../../assets/data/newsCreated.json';

  constructor(private http: HttpClient) {
  }

  /**
   * URL BASE
   */
  dir = 'https://dev.k8s.braveup.cl';

  /**
   * Obtiene noticia especifica
   * Rev.2
   * @param id
   */
  getNew(id: string): Observable<NewInterface> {
    const url = Constants.server + '/news/' + id;
    if (id !== '') {
      throw throwError(new Error('Don\'t use empty id'));
    }

    return this.http.get<HttpResponseOneNew>(url)
      .pipe(
        catchError(this.handleError)
      )
      .map(datos => datos.data.news);
  }

  /**
   * Obtiene las noticias de acuerdo a los filtros
   * @param courseId
   * @param roleId
   * @param schoolId
   */
  getNewsApproved(courseId?: number[], roleId?: number[], schoolId?: number[], levelId?: number[]): Observable<NewInterface[]> {
    const params = {};
    const url = Constants.server + '/news/';
    if (courseId) {params['course_id'] = String(courseId.join()); }
    if (roleId) {params['role_id'] = String(roleId.join()); }
    if (schoolId) {params['school_id'] = String(schoolId.join()); }
    if (levelId) {params['level_id'] = String(levelId.join()); }

    params['approved'] = '1';

    return this.http.get<HttpResponseManyNews>(url, {params: params})
      .pipe(
        catchError(this.handleError)
      )
      .map(datos => datos.data.news);
  }

  getNewsUnapproved(courseId?: number[], roleId?: number[], schoolId?: number[], levelId?: number[]): Observable<NewInterface[]> {
    const params = {};
    const url = Constants.server + '/news/';
    if (courseId) {params['course_id'] = String(courseId.join()); }
    if (roleId) {params['role_id'] = String(roleId.join()); }
    if (schoolId) {params['school_id'] = String(schoolId.join()); }
    if (levelId) {params['level_id'] = String(levelId.join()); }

    params['approved'] = '0';

    return this.http.get<HttpResponseManyNews>(url, {params: params})
      .pipe(
        catchError(this.handleError)
      )
      .map(datos => datos.data.news);
  }

  /**
   * Crear noticia
   * @param news
   */
  createNew(news: CreateNewInterface): Observable<any> {
    const url = Constants.server + '/news/';

    const formData = new FormData();

    formData.set('title', String(news.title));
    formData.append('content', news.content);

    if (news.files) {
      for (let file = 0; file < news.files.length; file ++) {
        formData.append('file', news.files[file]);
      }
    }
    if (news.rolesId) {formData.append('roles_id', news.rolesId.join()); }
    if (news.schoolsId) {formData.append('schools_id', news.schoolsId.join()); }
    if (news.levelsId) {formData.append('levels_id', news.levelsId.join()); }
    if (news.coursesId) {formData.append('courses_id', news.coursesId.join()); }
    if (news.images) {formData.append('images', news.images); }

    return this.http.post<any>
    (url,
      formData)
      .pipe(
        catchError(this.handleError)
      ).map(datos => datos.ok);
  }

  /**
   * Borra noticia especifica
   * @param id
   */
  deleteNew(id: number) {
    const url = Constants.server + '/news/' + id.toString();
    return this.http.delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Edita noticia especifica
   * Bug en backend, al enviar una foto, no se reemplaza la foto, si no que se añade, aun cuando no se envia la original
   * @param news
   * @param id
   */
  editNew(news: EditNewInterface, id: number): Observable<any> {
    const url = Constants.server + '/news/' + id.toString();
    const formData = new FormData();
    formData.set('approved', news.approved.toString());
    if (news.title !== undefined) {formData.append('title', news.title); }
    if (news.content !== undefined) {formData.append('content', news.content); }
    // if (news.images !== undefined) {formData.append('images', news.images); }
    if (news.files) {
      for (let file = 0; file < news.files.length; file ++) {
        formData.append('file', news.files[file]);
      }
    }

    return this.http.patch
    (url, formData)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Aprobar noticia especifica
   * @param id
   */
  approveNew(id: number): Observable<any> {
    const url = Constants.server + '/news/' + id;
    const formData = new FormData();
    formData.set('approved', '1');

    return this.http.patch
    (url, formData)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Maneja errores que puedan producir las llamadas
   * @param error
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return _throw(
      'Something bad happened; please try again later.');
  }

}
