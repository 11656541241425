import { Constants } from './../constants';
import { _throw } from 'rxjs/observable/throw';
import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpEvent,
    HttpHeaders,
    HttpParams
} from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { UserInterface } from './cadena-braveup.service';

export interface BinnacleEntryInterface {
    id: string;
    comment: string;
    updatedAt: Date;
}

export interface BinnacleInterface {
    id: number;
    user_id: number;
    target_user_id: number;
    description: string;
    createdAt: string;
    updatedAt: string;
    solved: number;
    tag: string;
    entries?: BinnacleEntryInterface[];
    user: UserInterface;
    target_user: TargetUserInterface;
}

export interface TargetUserInterface {
    id: number;
    first_login: string;
    last_login: null;
    url_avatar: null;
    first_name: string;
    last_name: string;
    email: string;
    gender: null;
    password_token: null;
    expiration_token: null;
    is_admin: number;
    is_superadmin: number;
    admin_group_id: null;
    superadmin_group_id: null;
    superadmin_group: null;
    admin_group: null;
}

export interface HttpResponseMany {
    ok: boolean;
    data?: BinnacleInterface[];
}

export interface HttpResponse {
    ok: boolean;
    data?: BinnacleInterface;
}

@Injectable({
    providedIn: 'root'
})
export class LogbookService {
    constructor(private http: HttpClient) {}

    /**
     * Obtiene bitacora especifica
     * @param BinnacleId
     */
    getBinnacle(binnacleId: string): Observable<BinnacleInterface> {
        if (binnacleId !== '') {
            throw throwError(new Error('Don\'t use empty id'));
        }
        return this.http
            .get<HttpResponse>(`${Constants.server}/binnacles/${binnacleId}`)
            .pipe(catchError(this.handleError))
            .map(datos => datos.data);
    }

    /**
     * Obtiene bitacoras basado en los filtros
     * @param name
     * @param schoolsId
     * @param coursesId
     * @param rolesId
     * @param usersId
     * @param tags
     */
    getBinnacles(
        name?: string,
        schoolsId?: number[],
        coursesId?: number[],
        rolesId?: number[],
        usersId?: number[],
        tags?: string[]
    ): Observable<BinnacleInterface[]> {
        const params = {};
        if (name) {
            params['name'] = name;
        }
        if (schoolsId) {
            params['schools_id'] = String(schoolsId.join());
        }
        if (coursesId) {
            params['courses_id'] = String(coursesId.join());
        }
        if (rolesId) {
            params['roles_id'] = String(rolesId.join());
        }
        if (tags) {
            params['tags'] = String(tags.join());
        }
        if (usersId) {
            params['target_user_id'] = String(usersId.join());
        }

        return this.http
            .get<HttpResponseMany>(`${Constants.server}/binnacles/`, {
                params: params
            })
            .pipe(catchError(this.handleError))
            .map(datos => {
                return datos.data;
            });
    }

    /**
     * Crea bitacora
     * @param targetId
     * @param description
     * @param tag
     */
    createBinnacle(
        targetId: string,
        description: string,
        tag: string
    ): Observable<BinnacleInterface> {
        const body = {
            target_user_id: targetId,
            description: description,
            tag: tag
        };

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http
            .post<HttpResponse>(
                `${Constants.server}/binnacles/`,
                body,
                httpOptions
            )
            .pipe(catchError(this.handleError))
            .map(datos => datos.data);
    }

    /**
     * Crea comentario en bitacora
     * @param comment
     * @param BinnacleId
     */
    createEntryBinnacle(
        comment: string,
        binnacleId: string
    ): Observable<BinnacleInterface> {
        const body = {
            comment: comment
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http
            .post<HttpResponse>(
                `${Constants.server}/binnacles/${binnacleId}`,
                body,
                httpOptions
            )
            .pipe(catchError(this.handleError))
            .map(datos => datos.data);
    }

    /**
     * Actualiza bitacora
     * @param BinnacleId
     * @param targetId
     * @param solved
     * @param description
     * @param tag
     */
    updateBinncale(
        binnacleId: string,
        targetId: number,
        solved: number,
        description: string,
        tag: string
    ): Observable<BinnacleInterface> {
        const body = {
            target_user_id: targetId,
            solved: solved,
            description: description,
            tag: tag
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http
            .post<HttpResponse>(
                `${Constants.server}/binnacles/${binnacleId}`,
                body,
                httpOptions
            )
            .pipe(catchError(this.handleError))
            .map(datos => datos.data);
    }

    /**
     * Resuelve bitacora
     * @param id
     * @param solved
     */
    solvedBinnacle(id: number, solved: number) {
        const body = {
            solved: solved
        };
        return this.http
            .patch<any>(`${Constants.server}/binnacles/${id}`, body)
            .pipe(catchError(this.handleError));
    }

    /**
     * Maneja errores que puedan producir las llamadas
     * @param error
     */
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                    `body was: ${error.error}`
            );
        }
        // return an observable with a user-facing error message
        return _throw('Something bad happened; please try again later.');
    }
}
